<template>
  <div class="customer-page-content">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-header">
              <div class="card-title">Customers</div>
            </div>
          </div>
          <div class="card-body" v-loading="loading">
            <ItemTable
              :show-actions="false"
              :columns="columnMap"
              :data="data"
              show-export
              export-file-name="Customers"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';
import ItemTable from '@/components/Util/ItemTable';
import { formatDate } from '@/utils/Helper';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Customer',
  components: { ItemTable },
  setup () {

    const store = useStore();

    const state = reactive({

      columnMap: [
        { field: 'name', header: 'Customer Name', sortable: false },
        { field: 'phone', header: 'Phone' },
        { field: 'email', header: 'Email', sortable: false },
        { field: 'point', header: 'Points', sortable: true },
        { field: 'orders_count', header: 'Orders', sortable: true },
        { field: 'created_at', header: 'Joined', sortable: false },
      ],

      data: computed(() => store.getters['customer/customers']),
      loading: computed(() => store.state.customer.loading)

    });

    const getCustomerData = async () => {

      const customers = store.getters['customer/customers'];

      if (customers.length) return;

      await store.dispatch('customer/getCustomers');

    };

    onMounted(async () => {
      await getCustomerData();
    });

    return {
      ...toRefs(state),
      formatDate
    };
  }
});
</script>

<style scoped>

</style>
